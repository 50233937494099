import React from "react"

const ProductTable = ({ products }) => {
  // Group products by category
  const groupedProducts = products.reduce((acc, product) => {
    const { category } = product.acf
    if (!acc[category]) {
      acc[category] = []
    }
    acc[category].push(product)
    return acc
  }, {})
  const categories = Object.keys(groupedProducts).reverse()
  let rowIndex = 0 // To track the overall index of rows

  return (
    <table className="table-auto w-full overflow-x-auto ">
      <thead>
        <tr className="text-sm text-left">
          <th>Name</th>
          <th>CAS Number</th>
          <th>Size</th>
          <th>Product Information</th>
          <th>MSDS</th>
          <th>Product Number</th>
          <th>Unit Price</th>
          <th>10 Pack</th>
          <th>20 Pack</th>
        </tr>
      </thead>
      <tbody>
        {categories.map(category => (
          <React.Fragment key={category}>
            <tr>
              <td colSpan="8" className=" py-2">
                <strong>{category}</strong>
              </td>
            </tr>
            {groupedProducts[category].map((product, index) => {
              const {
                name,
                size,
                cas_number,
                product_information,
                msds,
                product_number,
                unit_price,
                price_10_pack,
                price_20_pack,
              } = product.acf
              rowIndex++
              return (
                <tr
                  key={index}
                  className={
                    rowIndex % 2 === 0 ? "bg-primary/5" : "bg-primary/20"
                  }
                >
                  <td>{product.title.rendered}</td>
                  <td>{cas_number || "N/A"}</td>
                  <td>{size}</td>
                  <td>
                    <a
                      href={product_information}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 underline"
                    >
                      View
                    </a>
                  </td>
                  <td>
                    <a
                      href={msds}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 underline"
                    >
                      View
                    </a>
                  </td>
                  <td>{product_number}</td>
                  <td>{unit_price}</td>
                  <td>{price_10_pack}</td>
                  <td>{price_20_pack}</td>
                </tr>
              )
            })}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  )
}

export default ProductTable
